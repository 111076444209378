exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-company-js": () => import("./../../../src/pages/company.js" /* webpackChunkName: "component---src-pages-company-js" */),
  "component---src-pages-contactus-js": () => import("./../../../src/pages/contactus.js" /* webpackChunkName: "component---src-pages-contactus-js" */),
  "component---src-pages-demo-js": () => import("./../../../src/pages/demo.js" /* webpackChunkName: "component---src-pages-demo-js" */),
  "component---src-pages-fair-js": () => import("./../../../src/pages/fair.js" /* webpackChunkName: "component---src-pages-fair-js" */),
  "component---src-pages-imprint-js": () => import("./../../../src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-open-source-tsx": () => import("./../../../src/pages/open-source.tsx" /* webpackChunkName: "component---src-pages-open-source-tsx" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-product-comparison-tsx": () => import("./../../../src/pages/product-comparison.tsx" /* webpackChunkName: "component---src-pages-product-comparison-tsx" */),
  "component---src-pages-product-data-stories-tsx": () => import("./../../../src/pages/product-data-stories.tsx" /* webpackChunkName: "component---src-pages-product-data-stories-tsx" */),
  "component---src-pages-product-iiot-application-platform-tsx": () => import("./../../../src/pages/product-iiot-application-platform.tsx" /* webpackChunkName: "component---src-pages-product-iiot-application-platform-tsx" */),
  "component---src-pages-product-io-link-starter-kit-tsx": () => import("./../../../src/pages/product-io-link-starter-kit.tsx" /* webpackChunkName: "component---src-pages-product-io-link-starter-kit-tsx" */),
  "component---src-pages-product-manufacturing-insights-tsx": () => import("./../../../src/pages/product-manufacturing-insights.tsx" /* webpackChunkName: "component---src-pages-product-manufacturing-insights-tsx" */),
  "component---src-pages-product-more-information-tsx": () => import("./../../../src/pages/product-more-information.tsx" /* webpackChunkName: "component---src-pages-product-more-information-tsx" */),
  "component---src-pages-services-consulting-tsx": () => import("./../../../src/pages/services-consulting.tsx" /* webpackChunkName: "component---src-pages-services-consulting-tsx" */),
  "component---src-pages-services-development-tsx": () => import("./../../../src/pages/services-development.tsx" /* webpackChunkName: "component---src-pages-services-development-tsx" */),
  "component---src-pages-services-training-tsx": () => import("./../../../src/pages/services-training.tsx" /* webpackChunkName: "component---src-pages-services-training-tsx" */),
  "component---src-pages-solutions-construction-js": () => import("./../../../src/pages/solutions-construction.js" /* webpackChunkName: "component---src-pages-solutions-construction-js" */),
  "component---src-pages-solutions-iiot-applications-tsx": () => import("./../../../src/pages/solutions-iiot-applications.tsx" /* webpackChunkName: "component---src-pages-solutions-iiot-applications-tsx" */),
  "component---src-pages-solutions-iiot-infrastructure-tsx": () => import("./../../../src/pages/solutions-iiot-infrastructure.tsx" /* webpackChunkName: "component---src-pages-solutions-iiot-infrastructure-tsx" */),
  "component---src-pages-solutions-production-efficiency-tsx": () => import("./../../../src/pages/solutions-production-efficiency.tsx" /* webpackChunkName: "component---src-pages-solutions-production-efficiency-tsx" */),
  "component---src-pages-solutions-production-js": () => import("./../../../src/pages/solutions-production.js" /* webpackChunkName: "component---src-pages-solutions-production-js" */),
  "component---src-pages-solutions-transparency-tsx": () => import("./../../../src/pages/solutions-transparency.tsx" /* webpackChunkName: "component---src-pages-solutions-transparency-tsx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2021-10-01-startup-the-future-de-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/dominikriemer/Code/bytefabrik/website/src/posts/blog/2021-10-01-startup-the-future/de.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2021-10-01-startup-the-future-de-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2021-10-01-startup-the-future-en-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/dominikriemer/Code/bytefabrik/website/src/posts/blog/2021-10-01-startup-the-future/en.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2021-10-01-startup-the-future-en-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2021-10-25-bytefabrik-dashjoin-partnership-de-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/dominikriemer/Code/bytefabrik/website/src/posts/blog/2021-10-25-bytefabrik-dashjoin-partnership/de.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2021-10-25-bytefabrik-dashjoin-partnership-de-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2021-10-25-bytefabrik-dashjoin-partnership-en-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/dominikriemer/Code/bytefabrik/website/src/posts/blog/2021-10-25-bytefabrik-dashjoin-partnership/en.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2021-10-25-bytefabrik-dashjoin-partnership-en-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2021-11-01-vdma-de-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/dominikriemer/Code/bytefabrik/website/src/posts/blog/2021-11-01-vdma/de.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2021-11-01-vdma-de-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2021-11-01-vdma-en-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/dominikriemer/Code/bytefabrik/website/src/posts/blog/2021-11-01-vdma/en.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2021-11-01-vdma-en-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2021-11-24-bmwk-gruendungswettbewerb-de-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/dominikriemer/Code/bytefabrik/website/src/posts/blog/2021-11-24-bmwk-gruendungswettbewerb/de.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2021-11-24-bmwk-gruendungswettbewerb-de-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2021-11-24-bmwk-gruendungswettbewerb-en-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/dominikriemer/Code/bytefabrik/website/src/posts/blog/2021-11-24-bmwk-gruendungswettbewerb/en.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2021-11-24-bmwk-gruendungswettbewerb-en-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2022-01-11-microsoft-for-startups-de-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/dominikriemer/Code/bytefabrik/website/src/posts/blog/2022-01-11-microsoft-for-startups/de.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2022-01-11-microsoft-for-startups-de-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2022-01-11-microsoft-for-startups-en-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/dominikriemer/Code/bytefabrik/website/src/posts/blog/2022-01-11-microsoft-for-startups/en.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2022-01-11-microsoft-for-startups-en-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2022-05-24-digital-hub-pitch-night-de-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/dominikriemer/Code/bytefabrik/website/src/posts/blog/2022-05-24-digital-hub-pitch-night/de.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2022-05-24-digital-hub-pitch-night-de-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2022-05-24-digital-hub-pitch-night-en-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/dominikriemer/Code/bytefabrik/website/src/posts/blog/2022-05-24-digital-hub-pitch-night/en.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2022-05-24-digital-hub-pitch-night-en-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2022-05-30-hannover-messe-de-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/dominikriemer/Code/bytefabrik/website/src/posts/blog/2022-05-30-hannover-messe/de.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2022-05-30-hannover-messe-de-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2022-05-30-hannover-messe-en-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/dominikriemer/Code/bytefabrik/website/src/posts/blog/2022-05-30-hannover-messe/en.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2022-05-30-hannover-messe-en-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2022-06-08-euroguss-de-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/dominikriemer/Code/bytefabrik/website/src/posts/blog/2022-06-08-euroguss/de.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2022-06-08-euroguss-de-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2022-06-08-euroguss-en-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/dominikriemer/Code/bytefabrik/website/src/posts/blog/2022-06-08-euroguss/en.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2022-06-08-euroguss-en-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2022-06-24-automatica-de-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/dominikriemer/Code/bytefabrik/website/src/posts/blog/2022-06-24-automatica/de.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2022-06-24-automatica-de-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2022-06-24-automatica-en-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/dominikriemer/Code/bytefabrik/website/src/posts/blog/2022-06-24-automatica/en.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2022-06-24-automatica-en-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2022-08-17-ionos-streampipes-hackathon-de-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/dominikriemer/Code/bytefabrik/website/src/posts/blog/2022-08-17-ionos-streampipes-hackathon/de.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2022-08-17-ionos-streampipes-hackathon-de-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2022-08-17-ionos-streampipes-hackathon-en-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/dominikriemer/Code/bytefabrik/website/src/posts/blog/2022-08-17-ionos-streampipes-hackathon/en.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2022-08-17-ionos-streampipes-hackathon-en-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2022-11-10-cyberone-award-de-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/dominikriemer/Code/bytefabrik/website/src/posts/blog/2022-11-10-cyberone-award/de.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2022-11-10-cyberone-award-de-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2022-11-10-cyberone-award-en-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/dominikriemer/Code/bytefabrik/website/src/posts/blog/2022-11-10-cyberone-award/en.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2022-11-10-cyberone-award-en-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2023-03-23-streampipes-graduation-event-de-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/dominikriemer/Code/bytefabrik/website/src/posts/blog/2023-03-23-streampipes-graduation-event/de.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2023-03-23-streampipes-graduation-event-de-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2023-03-23-streampipes-graduation-event-en-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/dominikriemer/Code/bytefabrik/website/src/posts/blog/2023-03-23-streampipes-graduation-event/en.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2023-03-23-streampipes-graduation-event-en-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2023-04-17-hannover-messe-de-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/dominikriemer/Code/bytefabrik/website/src/posts/blog/2023-04-17-hannover-messe/de.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2023-04-17-hannover-messe-de-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2023-04-17-hannover-messe-en-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/dominikriemer/Code/bytefabrik/website/src/posts/blog/2023-04-17-hannover-messe/en.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2023-04-17-hannover-messe-en-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2023-04-20-streampipes-pycon-de-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/dominikriemer/Code/bytefabrik/website/src/posts/blog/2023-04-20-streampipes-pycon/de.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2023-04-20-streampipes-pycon-de-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2023-04-20-streampipes-pycon-en-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/dominikriemer/Code/bytefabrik/website/src/posts/blog/2023-04-20-streampipes-pycon/en.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2023-04-20-streampipes-pycon-en-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2023-04-26-building-iot-de-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/dominikriemer/Code/bytefabrik/website/src/posts/blog/2023-04-26-building-iot/de.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2023-04-26-building-iot-de-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2023-04-26-building-iot-en-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/dominikriemer/Code/bytefabrik/website/src/posts/blog/2023-04-26-building-iot/en.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2023-04-26-building-iot-en-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2023-05-25-streampipes-meetup-inovex-de-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/dominikriemer/Code/bytefabrik/website/src/posts/blog/2023-05-25-streampipes-meetup-inovex/de.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2023-05-25-streampipes-meetup-inovex-de-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2023-05-25-streampipes-meetup-inovex-en-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/dominikriemer/Code/bytefabrik/website/src/posts/blog/2023-05-25-streampipes-meetup-inovex/en.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2023-05-25-streampipes-meetup-inovex-en-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2023-06-08-bytefabrik-industry-40-tv-de-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/dominikriemer/Code/bytefabrik/website/src/posts/blog/2023-06-08-bytefabrik-industry40-tv/de.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2023-06-08-bytefabrik-industry-40-tv-de-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2023-06-08-bytefabrik-industry-40-tv-en-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/dominikriemer/Code/bytefabrik/website/src/posts/blog/2023-06-08-bytefabrik-industry40-tv/en.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2023-06-08-bytefabrik-industry-40-tv-en-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2023-07-08-kickoff-isolde-de-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/dominikriemer/Code/bytefabrik/website/src/posts/blog/2023-07-08-kickoff-isolde/de.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2023-07-08-kickoff-isolde-de-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2023-07-08-kickoff-isolde-en-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/dominikriemer/Code/bytefabrik/website/src/posts/blog/2023-07-08-kickoff-isolde/en.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2023-07-08-kickoff-isolde-en-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2023-10-12-data-2-day-de-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/dominikriemer/Code/bytefabrik/website/src/posts/blog/2023-10-12-data-2-day/de.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2023-10-12-data-2-day-de-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2023-10-12-data-2-day-en-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/dominikriemer/Code/bytefabrik/website/src/posts/blog/2023-10-12-data-2-day/en.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2023-10-12-data-2-day-en-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2024-04-hannover-messe-de-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/dominikriemer/Code/bytefabrik/website/src/posts/blog/2024-04-hannover-messe/de.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2024-04-hannover-messe-de-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2024-04-hannover-messe-en-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/dominikriemer/Code/bytefabrik/website/src/posts/blog/2024-04-hannover-messe/en.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-posts-blog-2024-04-hannover-messe-en-mdx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */)
}

